import React, { useEffect, useMemo, useState } from 'react'

import { loadScript, removeScriptTag } from '../../../../shared'
import { isValidUrl } from '../../utils/isValidUrl'

type Props = {
  provider?: string
  network?: string[]
  sortBy?: string
  packageType?: string
  numberOfDeals?: string
  contractLength?: string[]
  dataAmount?: string
  contractType?: string
  devices?: string[]
  widgetType: string
  showMore?: boolean
  showResellerDeals?: boolean
  featuredDealIds?: string
  deviceColours?: string[]
  dealsWithRewards?: boolean
}

export const WordyDealsWidget: React.FC<Props> = ({
  widgetType,
  provider = [],
  packageType = 'BROADBAND_ONLY',
  network = [],
  contractLength = [],
  dataAmount = '',
  contractType = '',
  numberOfDeals = '3',
  sortBy = 'MONTHLY_PRICE',
  devices = [],
  showMore = true,
  showResellerDeals = true,
  featuredDealIds = '',
  deviceColours = [],
  dealsWithRewards = false,
}) => {
  const { BROADBAND_DEALS_WIDGET_SCRIPT = '', MOBILE_DEALS_WIDGET_SCRIPT = '' } = process.env
  const [hasMounted, setHasMounted] = useState<boolean>(false)
  const mobileDealsWidgets = useMemo(
    () => ['MobileDealsWidgetSIMO', 'MobileDealsWidgetHandset'],
    []
  )

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      const script =
        widgetType === 'BroadbandDealsWidget'
          ? BROADBAND_DEALS_WIDGET_SCRIPT
          : mobileDealsWidgets.includes(widgetType)
            ? MOBILE_DEALS_WIDGET_SCRIPT
            : ''

      if (isValidUrl(script)) {
        loadScript({
          src: script,
        })
      }

      return () => {
        removeScriptTag(script)
      }
    }
  }, [
    widgetType,
    hasMounted,
    mobileDealsWidgets,
    BROADBAND_DEALS_WIDGET_SCRIPT,
    MOBILE_DEALS_WIDGET_SCRIPT,
  ])

  if (!hasMounted) {
    return null
  }

  if (widgetType === 'BroadbandDealsWidget') {
    return (
      <div
        data-stickee-widget-id="which-480"
        data-type="wordy"
        data-per-page={numberOfDeals}
        data-filters={`{"suppliers":[${provider}], "gift": ${dealsWithRewards}}`}
        data-fixed-filters={`{"package_types":["${packageType}"]}`}
        data-sort={sortBy}
        data-testid="broadband-deals-widget"
        data-deal-ids={featuredDealIds}
        data-site="which"
        data-hide-show-more={showMore}
      />
    )
  }

  const unlimitedData = 65535000
  const oneHundredGBData = 100000
  const tenGBData = 10000

  const getDataAmount = () => {
    switch (dataAmount) {
      case 'high':
        return { min: oneHundredGBData, max: unlimitedData }
      case 'medium':
        return { min: tenGBData, max: oneHundredGBData }
      case 'low':
        return { max: tenGBData }
      default:
        return { max: unlimitedData }
    }
  }

  const blackDevice = ['1']

  const constructMobileFixedFilters = () => {
    return {
      networks: network.map(Number),
      ...(contractType !== '' && { hardware_types: [contractType] }),
      ...(contractLength.length > 0 && { contract_lengths: contractLength.map(Number) }),
      ...(devices.length > 0 && { families: devices.map(Number) }),
      ...(!showResellerDeals && { resellers: 'EXCLUDE_RESELLERS' }),
      ...(widgetType === 'MobileDealsWidgetHandset' &&
        (deviceColours.length > 0
          ? { colours: deviceColours.map(Number) }
          : { colours: blackDevice })),
    }
  }

  const constructMobileFilters = () => {
    return {
      ...(dataAmount !== '' && { data: getDataAmount() }),
    }
  }

  if (mobileDealsWidgets.includes(widgetType)) {
    return (
      <div
        data-stickee-widget-id="which-502"
        data-type="wordy"
        data-per-page={numberOfDeals}
        data-filters={JSON.stringify(constructMobileFilters())}
        data-fixed-filters={JSON.stringify(constructMobileFixedFilters())}
        data-sort={sortBy}
        data-testid="mobile-deals-widget"
        data-show-more={showMore}
        data-deal-ids={featuredDealIds}
        data-site="which"
      />
    )
  }

  return null
}
