import type { FunctionComponent } from 'react'
import { GridItem } from '@which/seatbelt'

import { Loader } from '../../../../../shared/components/Loader'
import { compareProductsStorageName } from '../../../../../shared/constants/compare-products-storage-name'
import { useCompareTray } from '../../../../../shared/hooks/useCompareTray'
import { usePaths } from '../../../../../shared/hooks/usePaths'
import { EmptyCompareLandingPage } from '../EmptyCompareLandingPage/EmptyCompareLandingPage'
import { ComparisonTableContent } from './ComparisonTableContent/ComparisonTableContent'

export const ComparisonTable: FunctionComponent = () => {
  const { categorySlug } = usePaths()
  const taxonomySlug = categorySlug || ''
  const { compareTrayItems } = useCompareTray(compareProductsStorageName, taxonomySlug)

  if (!compareTrayItems) {
    return <Loader />
  }

  if (compareTrayItems.length === 0) {
    return (
      <GridItem span={{ small: 'full-bleed', medium: 'full-bleed' }}>
        <EmptyCompareLandingPage
          data-testid="emptyCompareLandingPage"
          numberOfProducts={compareTrayItems.length}
        />
      </GridItem>
    )
  }

  return (
    <GridItem span={{ small: 'full-bleed', medium: 'full-bleed' }}>
      <ComparisonTableContent taxonomySlug={taxonomySlug} />
    </GridItem>
  )
}

//////IMPLEMENTATION//////

export const totalProductColumns = 4
