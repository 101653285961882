import ProductHubPage from '../../pages/product-hub/ProductHubPage'
import { Redirect } from '../../shared/components/Redirect'
import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

/**
 * @TODO Break apart as more sub routes get added
 */
export const reviewRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/l/:subVerticalSlug/:productSlug',
    component: () => <ProductHubPage />,
  },
  {
    live: true,
    exact: true,
    path: '/l/:subVerticalSlug',
    component: () => (
      <Page pagePath="sub-vertical/SubVerticalPage" template="Subvertical" context="reviews" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/reviews/laptops/article/guides',
    component: () => <Redirect to="/l/computing/laptops" />,
  },
  {
    live: true,
    exact: true,
    path: '/reviews/washing-machines/article/guides',
    component: () => <Redirect to="/l/laundry/washing-machines" />,
  },
  {
    live: true,
    exact: true,
    path: '/reviews/televisions/article/guides',
    component: () => <Redirect to="/l/tv-and-home-entertainment/televisions" />,
  },
  {
    live: true,
    exact: true,
    path: '/reviews/:categorySlug/article/guides',
    component: () => (
      <Page
        pagePath="article-listing/ArticleListingPage"
        template="Reviews Article Listing"
        context="reviews"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/reviews/:superCategorySlug/article/:articleSlug',
    component: () => (
      <Page
        pagePath="article/ArticlePage"
        template="Article Advice Single Page"
        context="reviews"
      />
    ),
  },
  {
    // Recommendations route added to match and stop the MPG route matching these pages
    live: false,
    exact: true,
    path: '/reviews/:superCategorySlug/article/recommendations/:tableSlug',
    component: () => <div>Recommendations page placeholder</div>,
  },
  {
    live: true,
    exact: true,
    path: '/reviews/:superCategorySlug/article/:collectionSlug/:articleSlug',
    component: () => (
      <Page pagePath="article/ArticlePage" template="Article Advice Multi Page" context="reviews" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(tech-and-appliances|technology|appliances|cars|travel|home-and-garden|baby-and-child|family-and-health)',
    component: () => (
      <Page pagePath="vertical/VerticalPage" template="Reviews Vertical" context="reviews" />
    ),
  },
  {
    live: true,
    exact: true,
    path: ['/reviews/:categorySlug', '/reviews/:categorySlug/:manufacturer/brand'],
    component: () => (
      <Page
        pagePath="reviews/product-listing/ProductListingPage"
        template="Reviews Product Listing"
        context="reviews"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/reviews/:categorySlug/compare',
    component: () => (
      <Page
        pagePath="reviews/product-comparison/ProductComparisonPage"
        template="Reviews Product Comparison"
        context="reviews"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/reviews/:categorySlug/:productSlug',
    component: () => (
      <Page
        pagePath="reviews/product-page/ProductPage"
        template="Reviews Product Page"
        context="reviews"
      />
    ),
  },
]
