import type { FunctionComponent } from 'react'
import { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useParams } from 'react-router-dom'
import type { BadgeTheme, ProductColour, ProductScoreGaugeProps } from '@which/seatbelt'
import {
  AnimationWrapper,
  AnnualRunningCost,
  Badge,
  ButtonLink,
  ColourSwatchGroup,
  ProductScoreGauge,
  SaveButton,
  SignUp,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { ProductCardCompareCheckbox } from '@which/seatbelt/src/components/Cards/ProductCards/shared/ProductCardCompareCheckbox/ProductCardCompareCheckbox'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous'
import {
  AMAZON_AFFILIATE_TAG,
  dynamicDatalayerPush,
  dynamicGa4DataLayerPush,
  getSingularCategoryName,
  getTaggedAmazonUrl,
} from '@which/shared'

import classnames from 'classnames'

import type {
  AnnualRunningCost as AnnualRunningCostType,
  ProductOffer,
} from '../../../generated/frontend'
import { useAddSavedItemMutation, useRemoveSavedItemMutation } from '../../../generated/frontend'
import { ArticleTemplateContext } from '../../../pages/article/ArticleTemplateContext'
import { ArticleTrackonomicsLink } from '../../../pages/article/components/ArticleTrackonomicsLink'
import { ReviewsTemplateContext } from '../../../pages/reviews/product-listing/ReviewsTemplateContext'
import { ProductBadges } from '../../../pages/reviews/shared/components/ProductBadges/ProductBadges'
import {
  ADD_TO_SAVED,
  REMOVE_FROM_SAVED,
  SAVE_ERROR,
} from '../../../pages/reviews/shared/components/ToastNotification/constants'
import { modalImageProps } from '../../../pages/reviews/shared/data'
import { addHandlerToRunningCostTooltip } from '../../../pages/reviews/utils/add-handler-to-running-cost-tooltip'
import {
  getComparisonLimitReached,
  getIsSelectedForComparison,
} from '../../../pages/reviews/utils/comparison'
import { convertPriceValueToFloat } from '../../../pages/reviews/utils/convert-price-value-to-float'
import { mapAnnualRunningCost } from '../../../pages/reviews/utils/map-annual-running-cost'
import type { PageParams } from '../../../routes'
import type { CompareTray } from '../../../shared/types/ReviewsCompareTray'
import { usePageProps } from '../../../shared/usePageProps'
import { SavedRequestType } from '../../types'
import { formatRetailers } from '../../utils/formatRetailers'
import { Link } from '../Link'
import { SkipButton } from '../SkipButton'
import styles from './CardedDynamicTable.module.scss'
import { LoggedOutImage } from './LoggedOutImage'
import { TopRated } from './TopRated/TopRated'

export const TableItem: FunctionComponent<TableItemProps> = ({
  dynamicTableId,
  index,
  loginStatus,
  isAllowCompare,
  logInCTA,
  signUpCTA,
  trackingPrefix,
  availableOffersCount = 0,
  badgeNames = [],
  categorySlug,
  description,
  image = { images: [] },
  manufacturer,
  model,
  price,
  slug,
  productScoreGauge,
  keyTestResults = [],
  annualRunningCost,
  businessKey,
  key,
  variants,
  offers = [],
  topRated,
  isSelectedForComparison,
  isComparisonLimitReacted,
  addProductHandler,
  removeProductHandler,
  onSaveClick,
  saved,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants?.[0])
  const hasSafetyAlert = badgeNames.find((badge) => badge === 'safety alert')
  const filteredBadges = badgeNames.filter((badge) => badge !== 'safety alert')
  const manufacturerName = manufacturer?.name
  const validOffers = formatRetailers(offers)
  const singularCategoryName = getSingularCategoryName({
    categoryName: categorySlug?.replace(/-/g, ' '),
  })

  const formattedRunningCost =
    annualRunningCost &&
    addHandlerToRunningCostTooltip(mapAnnualRunningCost({ annualRunningCost, loginStatus }), () =>
      runningCostTooltipHandler(trackingPrefix)
    )

  const handleAffiliateClick = (validOffer: ProductOffer) => {
    const item_spec = new URL(validOffer.url).hostname

    dynamicDatalayerPush({
      eventCategory: 'Where to Buy',
      eventAction: 'Go to Retailer',
      eventLabel: `${
        validOffer.retailer.name
      } | ${manufacturerName} ${model} | ${convertPriceValueToFloat(validOffer.priceValue, true)}`,
      eventValue: Math.floor(Number(convertPriceValueToFloat(validOffer.priceValue))),
      item_url: validOffer.url,
      item_spec,
      item_group: trackingPrefix === 'spp' ? 'smart product picker' : 'dynamic table',
    })

    if (topRated) {
      dynamicDatalayerPush({
        eventCategory: 'ab test',
        eventAction: 'WHI302',
        eventLabel: 'Top card - where to buy clicks',
      })
    }
  }

  const addToCompare = () => {
    addProductHandler?.({
      manufacturer: manufacturerName ?? '',
      model: model ?? '',
      businessKey: key ?? '',
      slug: slug ?? '',
    })
  }

  const removeFromCompare = () => {
    removeProductHandler?.({
      manufacturer: manufacturerName ?? '',
      model: model ?? '',
      businessKey: key ?? '',
    })
  }

  return (
    <li key={`${dynamicTableId}-${index}`} className={styles.listItem} data-testid="cdt-card">
      <article className={classnames(styles.card, topRated && 'topRatedVariants2366')}>
        {/* Top rated variants; https://whichonline.atlassian.net/browse/RS-2366 */}
        {topRated && <TopRated />}
        {topRated && <TopRated link={`/reviews/${categorySlug}`} />}
        <div className={styles.wrapper}>
          {filteredBadges && (
            <div data-testid="badges">
              <ProductBadges badges={filteredBadges} className={styles.badges} />
            </div>
          )}
          <div className={styles.summary}>
            <div className={classnames(styles.imageContainer, styles.summaryImage)}>
              {hasSafetyAlert && (
                <Badge theme={'safety alert' as BadgeTheme} className={styles.safetyAlert} />
              )}
              {image?.images?.length ? (
                <picture>
                  {getSources(image.images)?.map(({ srcset, type }, imageIndex) => (
                    <source key={`image-source-${imageIndex + 1}`} srcSet={srcset} type={type} />
                  ))}
                  <img
                    className={styles.productImage}
                    src={selectProductImage(image.images)}
                    alt={`${manufacturerName} ${model}`}
                  />
                </picture>
              ) : (
                <LoggedOutImage
                  data-testid="logged-out-image"
                  data-which-id={`${trackingPrefix}-product-image-signup`}
                  className={styles.productImage}
                />
              )}
              {isAllowCompare && (
                <ProductCardCompareCheckbox
                  className={classnames(styles.compareCheckbox)}
                  manufacturer={manufacturerName ?? ''}
                  model={model ?? ''}
                  slug={slug ?? ''}
                  handleAddCompare={addToCompare}
                  handleRemoveCompare={removeFromCompare}
                  businessKey=""
                  isSelectedForComparison={isSelectedForComparison}
                  comparisonLimitReached={isComparisonLimitReacted}
                  type="all-breakpoints"
                />
              )}
            </div>
            <div className={styles.saveHeader}>
              <div className={styles.summaryDetails}>
                {model ? (
                  <div className={styles.section} data-testid="product-title">
                    <Typography tag="h3" textStyle="sb-text-heading-small">
                      {manufacturerName}
                    </Typography>
                    <Typography
                      className={styles.productTitle}
                      textStyle="sb-text-body-default-regular"
                    >
                      {model}
                    </Typography>
                  </div>
                ) : (
                  <div className={styles.section}>
                    <div className={styles.hiddenManufacturer} />
                    <div className={styles.hiddenModel} />
                  </div>
                )}
                {variants && selectedVariant && (
                  <div className={styles.colourSwatch}>
                    <ColourSwatchGroup
                      name={`colour-swatch-group-${businessKey}`}
                      variants={variants}
                      selectedVariant={selectedVariant}
                      onChange={setSelectedVariant}
                      classNameRadioGroup={styles.radioGroup}
                    />
                  </div>
                )}
                <div className={styles.section}>
                  {price && availableOffersCount === 0 && (
                    <>
                      <Typography
                        className={styles.price}
                        tag="span"
                        textStyle="sb-text-heading-small"
                      >
                        {price}
                      </Typography>
                      {availableOffersCount === 0 && (
                        <Typography
                          className={styles.typicalPriceLabel}
                          tag="span"
                          textStyle="sb-text-body-small-regular"
                        >
                          Typical price
                        </Typography>
                      )}
                    </>
                  )}
                  {availableOffersCount > 0 && (
                    <ol
                      className={styles.offersList}
                      data-which-id="cheapest-available-retailers"
                      data-testid="cheapest-available-retailers"
                    >
                      {validOffers.map((validOffer) => {
                        const { retailer, url, isTrackable, formattedPrice } = validOffer
                        const textStyle = 'sb-text-interface-body-small-strong'
                        return (
                          <li key={retailer.name} className={styles.offersListItem}>
                            {isTrackable ? (
                              <Typography tag="span" textStyle={textStyle}>
                                {formattedPrice}
                                <ArticleTrackonomicsLink
                                  href={url}
                                  contentType={'article'}
                                  optionalTracking={{
                                    item_group:
                                      trackingPrefix === 'spp'
                                        ? 'smart product picker'
                                        : 'dynamic table',
                                  }}
                                  onClick={() => handleAffiliateClick(validOffer)}
                                  className={styles.leftMargin}
                                >
                                  <Typography tag="span" textStyle={textStyle}>
                                    <AnimationWrapper>{retailer.name}</AnimationWrapper>
                                  </Typography>
                                </ArticleTrackonomicsLink>
                              </Typography>
                            ) : (
                              <Typography tag="span" textStyle={textStyle}>
                                {formattedPrice}
                                <Link
                                  href={getTaggedAmazonUrl(url, AMAZON_AFFILIATE_TAG)}
                                  onClick={() => handleAffiliateClick(validOffer)}
                                  className={classnames(styles.link, styles.leftMargin)}
                                  aria-label={`Buy from ${retailer.name} at ${formattedPrice}`}
                                  data-which-id="affiliate-link"
                                  target="_blank"
                                  rel="nofollow noopener noreferrer"
                                >
                                  <Typography tag="span" textStyle={textStyle}>
                                    <AnimationWrapper>{retailer.name}</AnimationWrapper>
                                  </Typography>
                                </Link>
                              </Typography>
                            )}
                          </li>
                        )
                      })}
                    </ol>
                  )}
                </div>
                {keyTestResults.length > 0 && (
                  <>
                    <ul className={styles.keyTestResultsList} data-testid="key-test-results">
                      {keyTestResults.map(({ label }) => (
                        <li key={label} className={styles.keyTestResultsListItem}>
                          <SolidPadlockIcon
                            data-testid="padlock-icon"
                            width={15}
                            height={15}
                            viewBox="0 0 15 15"
                            className={styles.padlockIcon}
                          />
                          <Typography tag="span" textStyle="sb-text-body-default-regular">
                            {label}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                    <Link
                      href={`/reviews/${categorySlug}`}
                      className={styles.listingLink}
                      data-which-id="productcard-all-reviews"
                    >
                      All {singularCategoryName} reviews
                    </Link>
                  </>
                )}
              </div>
              <SaveButton
                selected={saved}
                onClick={onSaveClick}
                productName={`${manufacturer} ${model}`}
              />
            </div>
            {productScoreGauge && (
              <ProductScoreGauge
                {...productScoreGauge}
                className={classnames(styles.section, styles.scoreAndBadges, styles.noMargin)}
              />
            )}
          </div>
          {description && (
            <Typography
              className={styles.description}
              data-testid="description"
              textStyle="sb-text-body-default-regular"
            >
              {description}
            </Typography>
          )}
          <ButtonLink
            className={styles.signuplink}
            href={signUpCTA?.url || `/reviews/${categorySlug}/${slug}#review`}
            appearance="secondary"
            data-test-element="review-button"
            data-tracking-element="review-button"
            data-which-id={
              signUpCTA?.url
                ? `${trackingPrefix}-product-signup`
                : `${trackingPrefix}-review-button`
            }
            data-testid="sign-up-button"
          >
            {signUpCTA?.label || 'Read full review'}
          </ButtonLink>
          {image?.images?.length && (
            <Link
              href={`/reviews/${categorySlug}`}
              className={styles.memberListingLink}
              data-which-id="productcard-all-reviews"
            >
              All {singularCategoryName} reviews
            </Link>
          )}
          {signUpCTA?.smallPrint && (
            <Typography textStyle="sb-text-body-small-regular">{signUpCTA.smallPrint}</Typography>
          )}
          {logInCTA && (
            <div className={styles.loginWrapper}>
              <Typography
                data-testid="login-cta"
                tag="span"
                textStyle="sb-text-interface-body-small-regular"
              >
                {logInCTA?.copy}
                <Link
                  className={styles.loginLink}
                  data-test-element="description-log-in"
                  data-which-id={`${trackingPrefix}-member-log-in-link`}
                  href={logInCTA.url}
                >
                  {logInCTA.label}
                </Link>
              </Typography>
            </div>
          )}
        </div>
        {formattedRunningCost && <AnnualRunningCost {...formattedRunningCost} />}
      </article>
      {isAllowCompare && isSelectedForComparison && (
        <SkipButton
          className={styles.skipToCompareButton}
          selector='button[data-testid="reviews-compare-button"]'
        >
          Skip to Compare Tray
        </SkipButton>
      )}
    </li>
  )
}

export const CardedDynamicTable: FunctionComponent<CardedDynamicTableProps> = ({
  dynamicTableId = '',
  logInCTA,
  products,
  signUpCTA,
  title,
  trackingPrefix = 'dynamic-table',
}) => {
  const { meta, compareTray } = useContext(ArticleTemplateContext)
  const loginStatus = meta.dataLayer?.[1]?.loginStatus
  const { state } = useContext(ReviewsTemplateContext)
  const { superCategorySlug: categorySlug } = useParams<PageParams>()
  const {
    savedItems,
    userAccessState: { transformTypeDecision },
  } = usePageProps()

  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    transformTypeDecision ?? ''
  )

  const [modalOpen, setModalOpen] = useState<{ isOpen: boolean; trackingId: string }>({
    isOpen: false,
    trackingId: '',
  })

  const savedProducts = savedItems?.find((i) => i.categorySlug === categorySlug)?.products ?? []

  const [savedProductsState, setSavedProductsState] = useState(
    savedProducts === null ? [] : savedProducts.flatMap((savedProduct) => savedProduct.id)
  )

  const isSavedProduct = (productId: string) => savedProductsState.includes(productId)

  const updateSavedState = (productId: string) => {
    setSavedProductsState((prevSavedProducts) => {
      const newState = prevSavedProducts.includes(productId)
        ? prevSavedProducts.filter((product) => product !== productId)
        : [...prevSavedProducts, productId]

      return newState
    })
  }

  const [addSavedItem, { loading: isAddingSavedProduct }] = useAddSavedItemMutation()
  const [removeSavedItem, { loading: isRemovingSavedProduct }] = useRemoveSavedItemMutation()

  const handleSavedResponse = ({
    responseSuccess,
    requestType,
    productId,
  }: HandleSavedResponseTypes) => {
    const isAddition = requestType === SavedRequestType.ADD

    if (responseSuccess) {
      if (isAddition) {
        state.setToastContext(ADD_TO_SAVED)
      } else {
        const undoRemove = () => {
          addSavedItem({
            variables: {
              item: {
                itemId: productId,
                itemCategory: categorySlug,
                itemType: 'product',
              },
            },
          })
            .then((response) => {
              if (response.data?.addSavedItem) {
                handleSavedResponse({
                  responseSuccess: response.data.addSavedItem.success,
                  requestType: SavedRequestType.ADD,
                  productId,
                })
              }
            })
            .catch(() => {
              handleSavedResponse({
                responseSuccess: false,
                requestType: SavedRequestType.ADD,
                productId,
              })
            })
          updateSavedState(productId)
        }
        state.setToastContext({
          ...REMOVE_FROM_SAVED,
          linkText: 'Undo',
          onClick: undoRemove,
        })
      }
    } else {
      state.setToastContext(SAVE_ERROR)
      updateSavedState(productId)
    }
  }

  const handleSaveClick = (product, tracking) => {
    if (isAddingSavedProduct || isRemovingSavedProduct) {
      return
    }

    const { key: businessKey, categorySlug, manufacturer, model } = product
    const { item_spec, modal_tracking } = tracking

    dynamicGa4DataLayerPush({
      event: 'click_save',
      utagid: 'WHC586DP01',
      item_group: 'saved items',
      item_product_id: businessKey,
      item_spec: item_spec,
      item_text: `${manufacturer} ${model}`,
      action_group: `${isSavedProduct(businessKey) ? 'remove from' : 'add to'} save`,
    })

    if (!transformTypeDecision?.includes('NO_ACCESS')) {
      updateSavedState(businessKey)
      isSavedProduct(businessKey)
        ? removeSavedItem({
            variables: {
              itemId: businessKey,
            },
          })
            .then((response) => {
              if (response.data?.removeSavedItem) {
                handleSavedResponse({
                  responseSuccess: response.data.removeSavedItem.success,
                  requestType: SavedRequestType.REMOVE,
                  productId: businessKey,
                })
              }
            })
            .catch(() => {
              handleSavedResponse({
                responseSuccess: false,
                requestType: SavedRequestType.REMOVE,
                productId: businessKey,
              })
            })
        : addSavedItem({
            variables: {
              item: {
                itemId: businessKey,
                itemCategory: categorySlug,
                itemType: 'product',
              },
            },
          })
            .then((response) => {
              if (response.data?.addSavedItem) {
                handleSavedResponse({
                  responseSuccess: response.data.addSavedItem.success,
                  requestType: SavedRequestType.ADD,
                  productId: businessKey,
                })
              }
            })
            .catch(() => {
              handleSavedResponse({
                responseSuccess: false,
                requestType: SavedRequestType.ADD,
                productId: businessKey,
              })
            })
    } else {
      setModalOpen({ isOpen: true, trackingId: modal_tracking })
      return
    }
  }

  if (!products?.length) {
    return null
  }

  const brands = new Set()

  products.forEach((product) => brands.add(product?.manufacturer?.slug))

  const brandString = Array.from(brands).join(',')

  return (
    <div
      data-dt-product-count={products.length}
      {...(brandString && { 'data-dt-product-brands': brandString })}
      data-testid="carded-dynamic-table"
      data-dynamic-table-id={dynamicTableId}
      className={styles.container}
    >
      {title && (
        <Typography className="h2-article" tag="h2" textStyle="sb-text-heading-large">
          {title}
        </Typography>
      )}

      <ul className={styles.list}>
        {products.map((product, index) => {
          const isSelectedForComparison = getIsSelectedForComparison({
            compareTrayItems: compareTray.compareTrayItems,
            slug: product.slug || '',
          })

          return TableItem({
            index,
            loginStatus,
            isAllowCompare: isPaidMember,
            logInCTA,
            signUpCTA,
            trackingPrefix,
            ...product,
            isSelectedForComparison,
            isComparisonLimitReacted: getComparisonLimitReached({
              compareTrayItems: compareTray.compareTrayItems,
            }),
            addProductHandler: compareTray.addProduct,
            removeProductHandler: compareTray.removeProduct_ProductCardHandler,
            onSaveClick: () =>
              handleSaveClick(product, {
                item_spec: `${trackingPrefix === 'spp' ? trackingPrefix : 'dt'} product card`,
                modal_tracking: 'mainsavebutton',
              }),
            saved: isSavedProduct(product.key),
          })
        })}
      </ul>

      {modalOpen.isOpen &&
        createPortal(
          <SignUp
            className={styles.modal}
            image={modalImageProps}
            overline="Recommended"
            title="Full access"
            closeModal={() => setModalOpen({ isOpen: false, trackingId: '' })}
            modalLinkDatalayerTrackingId={modalOpen.trackingId}
          />,
          document.body
        )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

export const runningCostTooltipHandler = (trackingPrefix: 'spp' | 'dynamic-table') => {
  dynamicGa4DataLayerPush({
    item_text: 'annual running cost',
    item_parent_text: 'product card',
    event: trackingPrefix === 'dynamic-table' ? 'clickTooltipDynamic' : 'clickTooltipSpp',
  })
}

export type CardedDynamicTableProduct = {
  availableOffersCount?: number
  badgeNames?: string[]
  categorySlug?: string
  description?: string
  image?: {
    images: Image[]
  }
  manufacturer?: {
    name: string
    slug: string
  }
  model?: string
  overallScore?: number
  price?: string
  slug?: string
  testDate?: string
  productScoreGauge?: ProductScoreGaugeProps
  keyTestResults?: { label: string; tooltip: string }[]
  annualRunningCost?: AnnualRunningCostType
  businessKey?: string
  key?: string
  variants?: ProductColour[][] | null
  offers: ProductOffer[]
  topRated: boolean
}

type SharedProps = {
  dynamicTableId?: string
  logInCTA?: { url: string; label: string; copy: string }
  signUpCTA?: { url: string; label: string; smallPrint?: string }
  trackingPrefix: 'spp' | 'dynamic-table'
}

export type CardedDynamicTableProps = SharedProps & {
  title?: string
  products?: CardedDynamicTableProduct[]
}

export type TableItemProps = SharedProps &
  CardedDynamicTableProduct & {
    index: number
    loginStatus?: any
    isAllowCompare?: boolean
    isSelectedForComparison: boolean
    isComparisonLimitReacted: boolean
    addProductHandler: CompareTray['addProduct']
    removeProductHandler: CompareTray['removeProduct_ProductCardHandler']
    handleSaveClick: (product: CardedDynamicTableProduct) => void
    saved: boolean
  }

export const formatSources = (image: Image) => {
  const sources: { srcset: string; type: string }[] = []

  if (image.webp) {
    sources.push({ srcset: `${image.webp}`, type: 'image/webp' })
  }

  sources.push({ srcset: `${image.jpg}`, type: 'image/jpg' })

  return sources
}

export const getSources = (images: Image[]) => {
  const frontImage = images.find((image) => image.view === 'front')

  if (frontImage) {
    return formatSources(frontImage)
  }

  return formatSources(images[0])
}

const selectProductImage = (images: Image[]) => {
  const frontImage = images.find((image) => image.view === 'front')
  return frontImage ? frontImage?.jpg : images[0].jpg
}

type Image = {
  view: string
  jpg: string
  webp: string | null
}

type HandleSavedResponseTypes = {
  responseSuccess: boolean
  requestType: SavedRequestType
  productId: string
}
