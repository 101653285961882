import type { FC } from 'react'
import { ContentCardV2 as ContentCard, Heading } from '@which/seatbelt'

import classnames from 'classnames'

import type { CategoryTypes } from '../../../../generated/frontend'
import styles from './ProductHubCategoryTypes.module.scss'

export const ProductHubCategoryTypes: FC<CategoryTypesProps> = ({ categoryTypes }) => {
  const { cards, title } = categoryTypes

  if (!cards || cards.length === 0 || !title) {
    return null
  }

  const heading = `Browse by ${title.toLowerCase()}`

  return (
    <section data-testid="category-types" id="category-types">
      <Heading heading={heading} headingTag="h2" headingType="medium" />
      <div
        className={classnames(
          {
            [styles.categoryTypesContainerTwo]: cards.length === 2,
            [styles.categoryTypesContainerThree]: cards.length === 3,
          },
          styles.categoryTypesContainer
        )}
      >
        {cards.slice(0, 4).map((card) => (
          <ContentCard
            key={`category-type-${card.title}`}
            title={card.title}
            titleTag="span"
            imgObj={{
              aspectRatioMain: 'two-to-one',
              imageAlt: '',
              imageSources: card.image.sources,
              str: card.image.src,
            }}
            trackingData={{
              'data-which-id': 'producthub-button',
            }}
            primaryLink={card.link}
          />
        ))}
      </div>
    </section>
  )
}
///////// IMPLEMENTATION /////////

type CategoryTypesProps = {
  categoryTypes: CategoryTypes
}
