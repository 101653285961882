import type { FC } from 'react'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Accordion } from '@which/seatbelt'
import { dynamicDatalayerPush, dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { ComparisonTableData, ProductDetail } from '../../../../../../generated/frontend'
import { compareProductsStorageName } from '../../../../../../shared'
import { useCompareTray } from '../../../../../../shared/hooks/useCompareTray'
import { useMatchMedia } from '../../../../../../shared/hooks/useMatchMedia'
import { usePageProps } from '../../../../../../shared/usePageProps'
import type { ComparisonTableState } from '../../../ComparisonTableContext'
import { ComparisonTableContext } from '../../../ComparisonTableContext'
import {
  REMOVE_TECH_SPECS_COLUMN,
  TABLE_ROWS_TO_BE_REFINED,
} from '../../../reducers/comparisonTableActionTypes'
import { totalProductColumns } from '../ComparisonTable'
import type { OnTableRowCheckboxArgs } from '../types'
import styles from './ComparisonTableContent.module.scss'
import { ComparisonTableContentFree } from './ComparisonTableContentFree'
import { ComparisonTableContentPaid } from './ComparisonTableContentPaid'
import { ComparisonPageHeader } from './components/ComparisonPageHeader'
import { ComparisonPageProducts } from './components/ComparisonPageProducts'

export const ComparisonTableContent: FC<ComparisonTablePageProps> = ({ taxonomySlug }) => {
  const {
    state: { productDetails, tableData, refineStatus, rowsToBeRefined },
    dispatch,
  } = useContext(ComparisonTableContext)

  const isDesktopOrAbove: boolean = useMatchMedia('(min-width: 1024px)')

  const [maxProductsToCompare, setMaxProductsToCompare] = useState(2)

  const filterProductDetails = () => {
    return Array.from(productDetails?.keys())?.splice(0, maxProductsToCompare)
  }

  const [productIndexesForComparison, setProductIndexesForComparison] =
    useState<number[]>(filterProductDetails())

  useEffect(() => {
    isDesktopOrAbove ? setMaxProductsToCompare(4) : setMaxProductsToCompare(2)
  }, [isDesktopOrAbove])

  useEffect(() => {
    setProductIndexesForComparison(filterProductDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxProductsToCompare, productDetails])

  const showRefine: boolean = isDesktopOrAbove && refineStatus === 'refine'

  const { userAccessState } = usePageProps()
  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    userAccessState?.transformTypeDecision ?? ''
  )
  const { compareTrayItems, removeProduct_CompareTrayHandler } = useCompareTray(
    compareProductsStorageName,
    taxonomySlug
  )

  const removeProductColumn = (businessKey: string, index?: number) => {
    removeProduct_CompareTrayHandler(businessKey, 'Remove From Compare - Compare Page')
    dispatch({
      type: REMOVE_TECH_SPECS_COLUMN,
      index,
    })

    setProductIndexesForComparison(filterProductDetails())
  }

  const hasOneProductBadge = useMemo(
    () => productDetails?.some((productDetail) => productDetail.badges.length),
    [productDetails]
  )

  const onTableRowCheckbox = ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => {
    dispatch({
      type: TABLE_ROWS_TO_BE_REFINED,
      row: value,
      sectionLabel,
    })

    dynamicDatalayerPush({
      eventCategory: 'Refine Results',
      eventAction: `${taxonomySlug} - ${sectionLabel.toLowerCase()} - ${rowLabel.toLowerCase()}`,
      eventLabel: `${!rowsToBeRefined[sectionLabel]?.includes(value)}`,
    })
  }

  const tooltipOpenHandler = useCallback(() => {
    dynamicGa4DataLayerPush({
      eventCategory: 'Product Compare',
      eventAction: 'Tooltip',
      eventLabel: `${taxonomySlug} - Product Card - Test Score`,
    })
  }, [taxonomySlug])

  const changeProductHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    indexToReplace: number
  ) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      const newIndex = parseInt(currentTarget?.value)

      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: currentTarget[currentTarget.selectedIndex].textContent ?? undefined,
      })

      setProductIndexesForComparison((currentIndexes) =>
        currentIndexes.map((currentIndex, i) => (i === indexToReplace ? newIndex : currentIndex))
      )
    }
  }

  const selectClickHandler = (event: React.MouseEvent<HTMLSelectElement>) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: 'change product',
      })
    }
  }

  const productDetailsForComparison = productIndexesForComparison
    .map((i) => productDetails[i])
    .filter(Boolean)

  const tableDataForComparison = tableData.map((comparisonTableData) => ({
    ...comparisonTableData,
    rows: comparisonTableData.rows.map((row) => ({
      ...row,
      rowValues: productIndexesForComparison.map((i) => row.rowValues[i]).filter(Boolean),
    })),
  }))

  const { testResults, techSpecs } = tableDataForComparison.reduce(
    (acc, item) => {
      if (item.dataType === 'test-result') {
        acc.testResults.push(item)
      } else {
        acc.techSpecs.push(item)
      }
      return acc
    },
    {
      testResults: [],
      techSpecs: [],
    } as {
      testResults: ComparisonTableState['tableData']
      techSpecs: ComparisonTableState['tableData']
    }
  )

  if (!productDetails?.length || !tableData?.length) {
    return null
  }

  const comparisonTableComponentProps = {
    productDetailsForComparison: productDetailsForComparison,
    isDesktopOrAbove,
    techSpecs: techSpecs,
    testResults: testResults,
    totalProductColumns: totalProductColumns,
    showRefine: showRefine,
    onTableRowCheckbox: onTableRowCheckbox,
    isPaidMember: isPaidMember,
  }

  const ComparisonTableComponent = isPaidMember ? (
    <ComparisonTableContentPaid {...comparisonTableComponentProps} />
  ) : (
    <ComparisonTableContentFree {...comparisonTableComponentProps} />
  )

  return (
    <div
      className={classnames(styles.tableWrapper, {
        [styles.tableWrapperDesktop]: isDesktopOrAbove,
      })}
      data-testid="comparison-table-content"
    >
      <ComparisonPageHeader
        changeProductHandler={changeProductHandler}
        compareTrayItems={compareTrayItems}
        isDesktopOrAbove={isDesktopOrAbove}
        productIndexesForComparison={productIndexesForComparison}
        removeProductColumn={removeProductColumn}
        selectClickHandler={selectClickHandler}
        taxonomySlug={taxonomySlug}
      />

      <ComparisonPageProducts
        productDetailsForComparison={productDetailsForComparison}
        taxonomySlug={taxonomySlug}
        hasOneProductBadge={hasOneProductBadge}
        tooltipOpenHandler={tooltipOpenHandler}
        isDesktopOrAbove={isDesktopOrAbove}
        totalProductColumns={totalProductColumns}
        isPaidMember={isPaidMember}
      />

      <div className={styles.accordionWrapper} data-testid="accordion-wrapper">
        <Accordion>{ComparisonTableComponent}</Accordion>
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

export type ComparisonTablePageProps = {
  taxonomySlug: string
}

export type ComparisonTableContentProps = {
  productDetailsForComparison: ProductDetail[]
  isDesktopOrAbove: boolean
  totalProductColumns: number
  testResults: ComparisonTableData[]
  showRefine: boolean
  onTableRowCheckbox: ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => void
  techSpecs: ComparisonTableData[]
  isPaidMember: boolean
}
